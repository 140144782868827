.portfolio-cards-holder {
  display: flex;
  flex-wrap: wrap;
}

.portfolio-card:hover {
  box-shadow: 10px 10px 20px rgba(8, 4, 33, 0.1);
}

.portfolio-card {
  transition: box-shadow 0.6s;
  background: #ffffff;
  border-radius: 20px;
  width: 420px;
  flex: 1 0 42%;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin: 40px;
  justify-content: center;
  cursor: pointer;
  padding-bottom: 10px;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 0;
    flex: none;
    margin-bottom: 20px;
    padding: 0;
  }
}

.portfolio-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  background-color: #735dfe;
  margin-bottom: 10px;
}

.project-title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: left;
  padding-left: 10px;
  color: #080421;
  padding-left: 20px;
  padding-right: 20px;
}

.project-description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: #30295b;
}

.button-more-left {
  display: flex;
  height: 36px;
  width: 120px;
  color: color(button-primary);
  background-color: color-bg(button-primary);
  border-color: color-border(button-primary);
  border-radius: 0px 20px 20px 20px !important;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;

  &:hover {
    background-color: color-bg(button-primary-hover);
    border-color: color-border(button-primary-hover);
  }

  &.is-loading {
    &::after {
      border-color: color(button-primary);
    }
  }
}

.view-more {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin-top: auto !important;
  margin-bottom: auto !important;
  padding-left: 20px;
  padding-right: 20px;
}

.image-left {
  width: 100%;
  height: 300px;
  object-fit: cover;
  background-color: #735dfe;
  margin-bottom: 10px;
  border-radius: 10px 30px 10px 0px;
  background-size: cover;
}

.image-right {
  width: 100%;
  height: 300px;
  object-fit: cover;
  background-color: #735dfe;
  margin-bottom: 10px;
  border-radius: 30px 10px 0px 10px;
  background-size: cover;
}

.type {
  position: absolute;
  width: 200px;
  height: 43px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  margin-top: -430px;
  margin-left: -30px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 10px 10px 20px rgba(8, 4, 33, 0.1);
}

.type-title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}
