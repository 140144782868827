.modal-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  width: 402px;
}

.modal-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: flex-end;
  text-align: center;

  margin-top: 5%;
  color: #080421;
}

.modal-body {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  text-align: center;

  color: #30295b;
}

.modal-image {
  width: 60px;
  height: 60px;
}

.modal-buttons-holder {
  display: flex;
  flex-direction: row;
}

.modal-cancel-button {
  width: 180px;
  height: 46px;

  color: #080421;
  background: #efeeee;
  border-radius: 10px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: #ffffff;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.modal-success-button {
  width: 180px;
  height: 46px;
  color: #fff;
  background: #735dfe;
  border-radius: 10px;
  padding: 10px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  /* identical to box height */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: #ffffff;
  margin-left: 15px;
  margin-right: 15px;
}

.react-modal {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 40px;
}

.Overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
}
