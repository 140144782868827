.subscribe-background {
  width: 100%;
  left: 0px;
  padding: 20px;
  background: #f5f7fb;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  border-radius: 20px 5px 5px 5px;
}

.subscribe-icon-holder {
  position: relative;
  width: 100%;
  margin-top: -40px;
  margin-left: auto;
  right: 0px;
}

.subscribe-icon {
  margin-right: -40px;
  margin-left: auto;
  width: 74px;
  height: 74px;
}

.subscribe-title {
  width: 50%;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 35px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #080421;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.subscribe-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #30295b;
}

.subscribe-body {
  justify-content: center;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.input-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.send-button {
  width: 150px;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.email-input {
  width: 50%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #efeeee;
  padding: 0px 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #30295b;
  background-color: #fff;
  margin-right: 3%;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-bottom: 10px;
  }
}
