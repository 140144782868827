.not-found-title {
  text-transform: uppercase;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  z-index: 1;

  text-align: center;

  color: #cccccc;
}

.not-found-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  /* identical to box height, or 215% */

  text-align: center;
  z-index: 1;

  color: #cccccc;
}

.not-found-404 {
  font-style: normal;
  font-weight: 800;
  font-size: 200px;
  line-height: 235px;
  z-index: 1;
  /* identical to box height */

  text-align: center;

  color: #735dfe;
}

.back-button-holder {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  z-index: 1;
}

.not-found-back-button {
  margin-right: 10px;
}

.not-found-back-text {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 160%;
  flex: 1;
  margin-bottom: 0;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  color: #000000;
}

.not-found-background {
  position: absolute;
  width: 70%;
  height: 50%;
  margin-left: 20%;
  z-index: 0;
}
