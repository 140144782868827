.project-section-background-left {
  width: 100%;
  left: 0;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

.project-section-background-right {
  width: 100%;
  left: 0px;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

.project-section-title {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  display: flex;
  align-items: center;

  color: #080421;
}

.project-section-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: justify;

  color: #30295b;
}

.project-section-is-device {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.project-section-is-device-label {
  margin-bottom: 0px;
  margin-right: 10px;
}

.project-section-image-left {
  border-radius: 5px 20px 5px 0px;
  width: 450px;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #735dfe;
}

.project-section-image-right {
  border-radius: 20px 5px 0px 5px;
  width: 450px;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #735dfe;
}

.project-section-image-holder {
  flex: 1;
  padding: 20px;

  @media screen and (max-width: 700px) {
    padding: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.project-section-body {
  padding: 80px;
  justify-content: center;
  display: flex;
  text-align: left;
  flex-direction: column;
  // flex: 1;

  @media screen and (max-width: 700px) {
    margin-top: 20px;
  }
}

.project-section-holder {
  display: flex;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.device-holder {
  position: absolute;
  width: 450px;
  height: 300px;
  display: flex;
  justify-content: center;
}
