.add-featuret-technology-holder {
  display: flex;
  flex-direction: row;
}

.add-project-input {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #efeeee;
  padding: 0px 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #30295b;
  background-color: #fff;
  margin-right: 3%;
}

.add-element-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.tagcloud01 {
  width: 480px;
  margin: 5em auto 2.5em;
}

.tagcloud01 ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tagcloud01 ul li {
  cursor: pointer;
  display: inline-block;
  margin: 0 0.3em 0.3em 0;
  padding: 0;
}
.tagcloud01 ul li a {
  display: inline-block;
  max-width: 100px;
  height: 28px;
  line-height: 28px;
  padding: 0 1em;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 3px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #333;
  font-size: 13px;
  text-decoration: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.tagcloud01 ul li a:hover {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: #fff;
}

.tag-selected {
  background-color: #735dfe !important;
  border: 1px solid #3498db !important;
  color: #fff !important;
}
