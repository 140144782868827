.infographics-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3%;
  text-align: center;
  background-color: color(secondary-light);
  flex-direction: row;
  border-radius: 20px;
  flex-wrap: wrap;
  margin: 5%;
}

.infographics-item-holder {
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
  flex-direction: row;
  align-items: center;

  flex-wrap: wrap;
}

.infographics-number {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  flex: 1;
  color: #735dfe;
  margin-bottom: 0px;
}

.infographics-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  flex: 1;
  color: #30295b;
  margin-bottom: 0px;
}

.progress {
  display: inline-block;
  width: 5px;
  height: 800px;
  margin: 35px;
  border-radius: 20px;
  background: #f9f9f9;

  @media screen and (max-width: 700px) {
  }
}

.progress-bar-holder-center {
  flex: 1;
  width: 30%;
}

.progress-bar-holder-left {
  flex: 1;
  width: 30%;
}

.progress-bar-holder-right {
  flex: 1;
  width: 30%;
}

.progress-bar-holder {
  display: flex;
  flex-direction: row;
  padding: 5%;
  align-items: center;
  justify-content: center;
}

.bar-width {
  border-radius: 20px;
  width: 100%;
  height: 0%;
  transition: width;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.36, 0.55, 0.63, 0.48);
}

.mobile {
  display: none;
}

.shadow {
  /* 25 50 */
}

.overlap {
  background-color: #735dfe;
}

@media screen and (max-width: 500px) {
  .github {
    margin: 20px;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: inherit;
  }
}

.about-detail-number {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 0px;
  color: #735dfe;
}

.about-detail-holder-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.about-detail-number-holder-left {
  display: flex;
  background-color: #dcd3fc;
  border-radius: 20px 5px 5px 5px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 40px;
  height: 40px;
  margin-bottom: 3%;
}

.about-detail-title-left {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  margin-bottom: 3%;
  text-align: right;

  color: #080421;
}
.about-detail-body-left {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 50%;
  text-align: right;
  color: #9da4b1;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.about-detail-holder-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.about-detail-number-holder-right {
  display: flex;
  background-color: #dcd3fc;
  border-radius: 5px 20px 5px 5px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 40px;
  height: 40px;
  margin-bottom: 3%;
}

.about-detail-title-right {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  margin-bottom: 3%;
  text-align: left;

  color: #080421;
}
.about-detail-body-right {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 50%;
  text-align: left;
  color: #9da4b1;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
}
