.header-holder {
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
  background-color: color(secondary-light);
  flex-direction: row;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 5%;
}

.path-holder {
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
  margin: auto;
  cursor: pointer;
  flex-direction: row;
}

.split {
  background-color: #735dfe;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
  margin-right: 5px;
}

.location {
  display: flex;
  flex-direction: row;
}

.path-name {
  margin-top: auto;
  margin-bottom: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 5px;
}

.bold {
  font-weight: 600;
}

.path-title {
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 65px;
  /* identical to box height, or 144% */

  text-align: center;

  color: #080421;
}

.header-logo {
  margin: 4%;
}

.path-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  margin-bottom: 0px !important;
  text-align: center;

  color: rgba(48, 41, 91, 0.4);
}

.path-split {
  width: 40px;
  height: 3px;
  background-color: #735dfe;
  border-radius: 3px;
  align-content: center;
  margin-bottom: 12px;
}

.details-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-holder {
  margin-bottom: 50px;
}
