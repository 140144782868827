.home-title {
  left: 133px;
  top: 251px;

  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 60px;
  /* or 120% */

  display: flex;
  align-items: center;

  color: #080421;
}

.home-body {
  left: 133px;
  top: 401px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  margin-top: 30px;
  margin-bottom: 60px;
  color: #30295b;
}

.hero-inner {
  /* Split hero in two half */
  display: flex;
  flex-wrap: wrap;
}

.hero-head {
  position: absolute;
  width: 60px;
  height: 60px;
  margin-top: 0px;
  margin-left: 30px;

  @media screen and (max-width: 700px) {
    margin-top: 30px;
  }
}

.hero-image-2 {
  position: absolute;
  width: 426px;
  height: 426px;
  margin-left: 160px;
  margin-top: 60px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(115, 93, 254, 0.4) 0%,
    rgba(115, 93, 254, 0) 100%
  );
  border-radius: 10px;
  opacity: 0.5;

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.hero-image-1 {
  position: absolute;
  width: 426px;
  height: 426px;
  margin-left: -50px;
  margin-top: -50px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(19, 92, 232, 0.25) 0%,
    rgba(19, 92, 232, 0) 100%
  );
  border-radius: 10px;
  opacity: 0.5;

  @media screen and (max-width: 700px) {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(96, 152, 255, 0.25) 0%,
      rgba(72, 135, 252, 0) 100%
    );
  }
}

.info-box-1 {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 50px;
  width: 366px;
  height: 228px;
  margin-top: 10px;
  margin-left: 20px;
  background: radial-gradient(
      100% 100% at 0% 0%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  backdrop-filter: blur(42px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 40px;
  border: rgba(255, 255, 255, 0.5) solid 3px;

  @media screen and (max-width: 700px) {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 50px;
    width: 95%;
  }
}

.info-box-2 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 163px;
  height: 163px;
  margin-left: 330px;
  margin-top: -30px;

  background: #ffffff;
  box-shadow: 0px 0px 60px rgba(115, 93, 254, 0.06);
  border-radius: 10px;

  @media screen and (max-width: 700px) {
    width: 100px;
    height: 100px;

    margin-left: 320px;
    margin-top: 0px;
  }
}

.info-box-3 {
  position: absolute;
  width: 302px;
  height: 98px;
  margin-left: 140px;
  margin-top: 190px;

  background: #735dfe;
  border-radius: 10px;
  box-shadow: 0px 0px 60px rgba(115, 93, 254, 0.06);

  @media screen and (max-width: 700px) {
    margin-top: 250px;
    margin-left: 150px;
  }
}

.info-box {
  flex: 3 0 60%;
  margin-bottom: 8%;
}

.image-box {
  flex: 2 0 40%;
  min-height: 350px;
}

.hero-images-holder {
  @media screen and (max-width: 700px) {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: -20px;
    padding: 0;
  }
}

.info-box-1-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding: 10px;
  /* or 133% */

  text-align: center;

  color: #30295b;
}
