.example-card {
  transition: box-shadow 0.6s;
  background: #ffffff;
  border-radius: 20px;
  width: 250px;
  height: 220px;
  flex: 1 0 21%;
  display: flex;
  flex-direction: column;
  margin: 10px;
  cursor: pointer;
  min-width: 20%;
  @media screen and (max-width: 700px) {
    min-width: 100%;
    margin: 0;
    margin-bottom: 10px;
  }
  background-size: cover;
}

.example-holder {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 3%;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.example-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 0 !important;
  padding: 10px;
  color: #ffffff;
}

.example-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: justify;

  color: #9da4b1;
}

.example-link-holder {
  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.example-card-color {
  background-color: rgba(115, 93, 254, 0.7);
  height: 100%;
  width: 100%;

  transition: box-shadow 0.6s;
  border-radius: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.example-title-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 10px;
  color: #ffffff;
}

.example-card-color-selected {
  background-color: #f5f7fb;
  height: 100%;
  width: 100%;

  transition: box-shadow 0.6s;
  border-radius: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding-top: 5%;
  cursor: pointer;
}

.example-title-holder-selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  color: #735dfe;
}

.example-title-selected {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 0 !important;
  padding: 10px;
  color: #735dfe;
}

.example-description {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: justify;
  padding-left: 10%;
  padding-right: 10%;

  color: #9da4b1;
}
